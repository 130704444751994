import React from 'react'
import {graphql, Link} from 'gatsby'
import Header from '../components/header'
import Footer from '../components/footer'
import SEO from '../components/seo'
import styles from './roadmap.module.css'
const stripMarkdownOneline = require('strip-markdown-oneline')

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }

    #allGitlabIssue(
    #  filter: {labels: {elemMatch: {name: {eq: "phase::post-mortem"}}}}
    #) {
    #  edges {
    #    node {
    #      title
    #      iid
    #      state
    #      labels {
    #        name
    #        description
    #        color
    #        text_color
    #        priority
    #      }
    #      description
    #      assignees {
    #        username
    #      }
    #    }
    #  }
    #}

    allAuthorsCsv {
      edges {
        node {
          handle
          name
          gitlab
        }
      }
    }
  }
`

function ellipsize(str, len = 150) {
  return str.length > len ? str.slice(0, len) + '...' : str
}

function Epic(props) {
  const {iid, title, description, dark} = props
  const className = dark ? styles.epicDark : styles.epic
  return (
    <Link to={`/epic/${iid}`} className={className}>
      <h3>{title}</h3>
      <p>{ellipsize(stripMarkdownOneline(description))}</p>
    </Link>
  )
}

export default function Features({data}) {
  const {allGitlabIssue, allAuthorsCsv, site} = data

  //const epics = allGitlabIssue.edges.map(({node}) => {
  //  if (node.assignees.length > 0) {
  //    for (let i = 0; i < node.assignees.length; i++) {
  //      const username = node.assignees[i].username
  //      const name = allAuthorsCsv.edges.find(
  //        ({node: author}) => author.gitlab === username
  //      ).node.name
  //      node.assignees[i].name = name
  //    }
  //  }
  //  return node
  //})

  const title = 'Features'
  return (
    <>
      <SEO title={title} />
      <Header siteTitle={site.siteMetadata.title} />

      <div className={styles.container}>
        <h1 className={styles.title}>{title}</h1>
        <p style={{marginBottom: '40px'}}>
          Here we showcase the features our team has built and deployed to users
          so far. Click on a feature to read why we built it, who it serves, and
          more! Planned features in <Link to={'/roadmap'}>our roadmap</Link> end
          up here when they are completed.
        </p>

        {/*<div className={styles.epics}>
          {epics.map(issue => (
            <Epic key={issue.iid} {...issue} />
          ))}
        </div>*/}
        <div className={styles.container}><p>Unable to load features</p></div>
      </div>

      <Footer />
    </>
  )
}
